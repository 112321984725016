<template>
  <v-card>
    <v-card-title class="headline">{{ $t('competitions.selectPoule') }}</v-card-title>
    <v-card-text>
      <p>{{ $t('competitions.selectPouleExpl') }}</p>
      <v-radio-group v-model="pouleId">
        <v-radio v-for="poule in poules" :key="poule.id" :label="poule.name" :value="poule.id" color="primary" />
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="primary" :loading="saving" :disabled="saving" text @click="done">Done</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import CompUser from '@/models/CompUser'

export default {
  props: {
    poules: { type: Array, default: () => [] },
    compUser: { type: CompUser, default: {} },
  },
  data: () => ({
    pouleId: null,
    saving: false,
  }),
  created() {
    this.pouleId = this.compUser.poule_id
  },
  methods: {
    ...mapActions('dialog', ['close']),
    async done() {
      this.saving = true
      await this.compUser.$apiUpdate({ poule_id: this.pouleId })
      this.saving = false
      this.close(true)
    },
  },
}
</script>
